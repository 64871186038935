import { AnalyticsEvent } from '.';
import { useTrackEvent } from './trackEvent';

const timeToAppStartMs = performance.now();

let loadTracked = false;

export const useTrackPerformance = () => {
	const trackEvent = useTrackEvent();

	if (loadTracked) return;

	const timeToAppReadyMs = performance.now();

	trackEvent(AnalyticsEvent.app_loaded, {
		time_to_app_start_ms: timeToAppStartMs,
		time_to_app_ready_ms: timeToAppReadyMs,
		app_is_iframed: inFrame(),
	});

	loadTracked = true;
};

const inFrame = () => {
	try {
		return window.self !== window.top;
	} catch {
		return true;
	}
};

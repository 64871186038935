import Cher from '@wearemojo/cher';
import { useEffect } from 'react';

import { SetWhoopsOptions, useWhoopsHandler } from './WhoopsProvider';

const useErrorHandler = (error?: Cher, whoopsOptions?: SetWhoopsOptions) => {
	const whoops = useWhoopsHandler();

	useEffect(() => {
		if (error) {
			whoops(error, whoopsOptions);
		}
	}, [error, whoops, whoopsOptions]);
};

export default useErrorHandler;

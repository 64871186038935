import { useEffect, useState } from 'react';

import { navigationRef } from '../utils/navigation';

const useIsNavigationReady = () => {
	/*
		Why? On load, navigation isn't immediately ready, which breaks routes that
		may wish to automatically navigate to another route

		Also note:
		"The onReady prop and navigationRef.isReady() work slightly differently. The onReady callback fires when NavigationContainer finishes mounting and we have also checked for deep links. The navigationRef.isReady() method additionally checks if there are any navigators rendered - which may not be true if the user is rendering their navigators conditionally inside a NavigationContainer that's not rendered conditionally."

		https://github.com/react-navigation/react-navigation/discussions/10506
	*/
	const [isReady, setIsReady] = useState(navigationRef.current?.isReady());

	useEffect(() => {
		let check: ReturnType<typeof setInterval>;
		if (!isReady) {
			check = setInterval(() => {
				if (navigationRef.current?.isReady()) {
					setIsReady(true);
					clearInterval(check);
				}
			}, 100);
		}
		return () => clearInterval(check);
	}, [isReady]);

	return isReady;
};

export default useIsNavigationReady;

import { FreeUserFirstCourseCompleteView } from '@wearemojo/ui-components';

import { FragmentType } from '../content/FragmentType';
import { useFragment } from '../hooks/content/useFragment';
import ModalManager from '../ModalManager';

type Props = {
	onCtaPress: () => void;
};

const FreeUserFirstCourseCompleteModal = ({ onCtaPress }: Props) => {
	const { closeModal } = ModalManager.useModal();
	const { title, content, ctaText } = useFragment(
		FragmentType.FreeUserFirstCourseCompleted,
	);

	return (
		<FreeUserFirstCourseCompleteView
			title={title}
			content={content}
			ctaText={ctaText}
			onCtaPress={onCtaPress}
			onClosePress={closeModal}
		/>
	);
};

export default FreeUserFirstCourseCompleteModal;

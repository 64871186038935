import {
	AudioMetadata,
	AudioPlayer,
	AudioPlayerEvent,
	AudioPlayerEventPayload,
	AudioPlayerInstance,
	AudioPlayerInstanceId,
	AudioPlayerMeta,
	AudioPlayerProgress,
	AudioPlayerStatus,
	AudioSource,
	PlaybackRateType,
} from '@wearemojo/ui-components';
import EventEmitter from 'eventemitter3';
import { Audio as ExpoAudio, AVPlaybackStatus } from 'expo-av';
import { useEffect, useState } from 'react';

import { logger } from '../utils/logging';

const ERRORS_TO_IGNORE = [
	'The Sound is already loading.',
	'The Sound is already loaded.',
];

const createMeta = (instanceId: string): AudioPlayerMeta => ({
	source: undefined,
	metadata: {},
	instanceId,
	isActiveInstance: false,
	attachCount: 0,
});

const createStatus = (): AudioPlayerStatus => ({
	isLoaded: false,
	isLoading: false,
	isPlaying: false,
	isLooping: false,
	playbackRate: 1,
	error: undefined,
});

const createProgress = (): AudioPlayerProgress => ({
	durationMs: 0,
	positionMs: 0,
});

const createAudioPlayer = (): AudioPlayer => {
	console.debug('createAudioPlayer');

	const events = new EventEmitter();

	const instances: Record<AudioPlayerInstanceId, AudioPlayerInstance> = {};
	const metas: Record<AudioPlayerInstanceId, AudioPlayerMeta> = {};
	const statuses: Record<AudioPlayerInstanceId, AudioPlayerStatus> = {};
	const progresses: Record<AudioPlayerInstanceId, AudioPlayerProgress> = {};

	let activeInstanceId: AudioPlayerInstanceId | undefined;

	const _playerRef = {
		current: undefined as ExpoAudio.Sound | undefined,
	};
	const getPlayer = async (forceNewInstance = false) => {
		if (forceNewInstance || !_playerRef.current) {
			// Expo won't load the same source again (even with a different source inbetween)
			if (_playerRef.current) {
				_playerRef.current.setOnPlaybackStatusUpdate(null);
				await _playerRef.current.unloadAsync();
			}
			console.debug('createAudioPlayer new ExpoAudio');
			_playerRef.current = new ExpoAudio.Sound();
			_playerRef.current.setOnPlaybackStatusUpdate(
				(status: AVPlaybackStatus) => {
					if (activeInstanceId) {
						if (status.isLoaded) {
							const durationMs = !Number.isNaN(status.durationMillis)
								? (status.durationMillis ?? 0)
								: 0;
							progresses[activeInstanceId] = {
								durationMs,
								positionMs: status.positionMillis,
							};
							events.emit(AudioPlayerEvent.progress);

							updateStatus(activeInstanceId, {
								isLoaded: status.isLoaded,
								isLoading: false,
								isPlaying: status.isPlaying,
								isLooping: status.isLooping,
							});
						} else {
							updateStatus(activeInstanceId, {
								isLoaded: false,
								isPlaying: false,
							});
						}
					}
				},
			);
		}
		return _playerRef.current;
	};

	const updateProgress = (
		instanceId: AudioPlayerInstanceId,
		newProgress: Partial<AudioPlayerProgress> = {},
	) => {
		const nextProgress = {
			...safeGet(progresses, instanceId),
			...newProgress,
		};

		if (hasShallowChange(safeGet(progresses, instanceId), newProgress)) {
			progresses[instanceId] = nextProgress;
		}

		events.emit(AudioPlayerEvent.progress);
	};

	const updateStatus = (
		instanceId: AudioPlayerInstanceId,
		newStatus: Partial<AudioPlayerStatus> = {},
	) => {
		const nextStatus = {
			...safeGet(statuses, instanceId),
			...newStatus,
		};

		if (hasShallowChange(safeGet(statuses, instanceId), newStatus)) {
			statuses[instanceId] = nextStatus;
		}

		for (const id of Object.keys(statuses)) {
			if (id === instanceId) {
				continue;
			}

			const updatedStatus = {
				...safeGet(statuses, id),
				// Non-active instances cannot be loaded or playing
				isLoaded: false,
				isLoading: false,
				isPlaying: false,
			};

			if (hasShallowChange(safeGet(statuses, id), updatedStatus)) {
				statuses[id] = updatedStatus;
			}
		}

		events.emit(AudioPlayerEvent.status);
	};

	const updateMeta = (
		instanceId: AudioPlayerInstanceId,
		{ isActiveInstance, source, metadata }: Partial<AudioPlayerMeta> = {},
	) => {
		const newActiveMeta = {
			...safeGet(metas, instanceId),
			isActiveInstance:
				isActiveInstance ?? safeGet(metas, instanceId).isActiveInstance,
			source: source ? { ...source } : safeGet(metas, instanceId).source,
			metadata: { ...metadata } ?? safeGet(metas, instanceId).metadata,
		};
		if (hasShallowChange(safeGet(metas, instanceId), newActiveMeta)) {
			metas[instanceId] = newActiveMeta;
		}

		activeInstanceId = isActiveInstance ? instanceId : activeInstanceId;
		for (const id of Object.keys(metas)) {
			const updatedMeta = {
				...safeGet(metas, id),
				isActiveInstance: id === activeInstanceId,
			};
			if (hasShallowChange(safeGet(metas, id), updatedMeta)) {
				metas[id] = updatedMeta;
			}
		}
		events.emit(AudioPlayerEvent.meta);
	};

	const createInstance = (instanceId: AudioPlayerInstanceId) => {
		const isActiveInstance = () => activeInstanceId === instanceId;

		const loadSource = async (source: AudioSource) => {
			if (!source) {
				logger.captureError('Unable to load audio, source is undefined');
				return;
			}

			if (isActiveInstance() && safeGet(statuses, instanceId).isLoaded) {
				return;
			}

			const { positionMs } = safeGet(progresses, instanceId);
			const player = await getPlayer(true);
			await player.loadAsync(source, { shouldPlay: false });
			activeInstanceId = instanceId;
			return new Promise((resolve) => {
				setTimeout(async () => {
					/*
						Player doesn't know duration event after initial loading,
						however triggering setPosition(0) forces it to resolve it
					*/
					await player.setPositionAsync(positionMs);
					resolve(undefined);
				}, 100);
			});
		};

		return {
			preload: () => {
				// Cavet: any currently playing audio will cease, as we load-into/reset the player
				(async () => {
					const newStatus: Partial<AudioPlayerStatus> = {};
					newStatus.isLoading = true;
					try {
						await loadSource(safeGet(metas, instanceId).source);
						newStatus.isLoaded = true;
					} catch (preloadError: any) {
						const message = String(preloadError?.message);
						newStatus.error = message;
						newStatus.isLoaded = false;
					}
					newStatus.isLoading = false;
					updateStatus(instanceId, newStatus);
				})();
			},
			play: () => {
				(async () => {
					const newStatus: Partial<AudioPlayerStatus> = {};
					try {
						const { positionMs } = safeGet(progresses, instanceId);
						const { playbackRate } = safeGet(statuses, instanceId);
						if (!safeGet(statuses, instanceId).isLoaded) {
							newStatus.isLoading = true;
							await loadSource(safeGet(metas, instanceId).source);
							newStatus.isLoading = false;
							newStatus.isLoaded = true;
						}
						const player = await getPlayer();
						await player.setRateAsync(playbackRate, true);
						await player.playFromPositionAsync(positionMs);
						newStatus.isPlaying = true;
					} catch (playError: any) {
						// @TODO: better error type^^
						const message = String(playError?.message);
						newStatus.isLoading = false;

						if (!ERRORS_TO_IGNORE.includes(message)) {
							logger.captureError(`Error loading audio ${message}`);
							newStatus.error = message;
							newStatus.isLoaded = false;
						}
					} finally {
						updateStatus(instanceId, newStatus);
					}
				})();

				updateMeta(instanceId, { isActiveInstance: true });
			},
			pause: () => {
				if (!isActiveInstance()) return;
				(async () => {
					const player = await getPlayer();
					await player.pauseAsync();
				})();
				updateStatus(instanceId, { isPlaying: false });
			},
			stop: () => {
				if (!isActiveInstance()) return;
				(async () => {
					const player = await getPlayer();
					await player.stopAsync();
				})();
				updateStatus(instanceId, { isPlaying: false });
			},
			getMeta: () => safeGet(metas, instanceId),
			getStatus: () => safeGet(statuses, instanceId),
			getProgress: () => safeGet(progresses, instanceId),
			setProgress: (progress: Partial<AudioPlayerProgress>) => {
				const { positionMs } = progress;
				if (isActiveInstance() && positionMs) {
					(async () => {
						const player = await getPlayer();
						await player.setPositionAsync(positionMs);
					})();
				}
				updateProgress(instanceId, progress);
			},
			jumpPositionTo: async (sec: number) => {
				if (isActiveInstance()) {
					const player = await getPlayer();
					// set position async is in ms
					await player.setPositionAsync(sec * 1000);
				}
			},
			jumpPositionBy: async (sec: number) => {
				if (isActiveInstance()) {
					const player = await getPlayer();
					const status = await player.getStatusAsync();
					if (status.isLoaded) {
						const newPosition = status.positionMillis + sec * 1000;
						await player.setPositionAsync(newPosition);
					}
				}
			},
			setSource: (source: AudioSource) => {
				safeGet(statuses, instanceId).isLoaded = false;
				safeGet(statuses, instanceId).isLoading = false;
				updateMeta(instanceId, { source });
			},
			setMetadata: (metadata: AudioMetadata) => {
				updateMeta(instanceId, { metadata });
			},
			setPlaybackRate: (playbackRate: PlaybackRateType) => {
				if (isActiveInstance()) {
					(async () => {
						const player = await getPlayer();
						await player.setRateAsync(playbackRate, true);
						updateStatus(instanceId, { playbackRate });
					})();
				}
			},
			on: <
				Event extends AudioPlayerEvent,
				Payload extends AudioPlayerEventPayload[Event],
			>(
				event: Event,
				callback: (payload: Payload) => void,
			) => {
				events.on(event, () => {
					const payload = getPayload(instanceId, event);
					callback({ ...payload } as Payload);
				});
				return () => events.off(event, callback);
			},
			hooks: {
				useStatus: createEventHook(instanceId, AudioPlayerEvent.status),
				useMeta: createEventHook(instanceId, AudioPlayerEvent.meta),
				useProgress: createEventHook(instanceId, AudioPlayerEvent.progress),
			},
		};
	};

	const getPayload = <Event extends AudioPlayerEvent>(
		instanceId: AudioPlayerInstanceId,
		event: Event,
	) => {
		switch (event) {
			case AudioPlayerEvent.status:
				return statuses[instanceId];
			case AudioPlayerEvent.meta:
				return metas[instanceId];
			case AudioPlayerEvent.progress:
				return progresses[instanceId];
			default:
				throw new Error('Unknown AudioPlayerEvent');
		}
	};

	const createEventHook = <
		Event extends AudioPlayerEvent,
		Payload extends AudioPlayerEventPayload[Event],
	>(
		instanceId: AudioPlayerInstanceId,
		event: Event,
	) =>
		function useEventPayloadHook(): Payload {
			const instance = safeGet(instances, instanceId);
			const [payload, setPayload] = useState(
				getPayload(instanceId, event) as Payload,
			);
			const callback = (next: Payload) => {
				setPayload((current) => {
					if (hasShallowChange(current, next)) {
						return { ...next };
					}
					return current;
				});
			};
			useEffect(() => instance.on(event, callback), [instance]);
			useEffect(() => {
				events.emit(event);
			}, []);
			return payload;
		};

	const attach = (instanceId: AudioPlayerInstanceId): AudioPlayerInstance => {
		console.debug('createAudioPlayer attach');
		statuses[instanceId] = statuses[instanceId] ?? createStatus();
		metas[instanceId] = metas[instanceId] ?? createMeta(instanceId);
		progresses[instanceId] = progresses[instanceId] ?? createProgress();
		instances[instanceId] = instances[instanceId] ?? createInstance(instanceId);
		safeGet(metas, instanceId).attachCount++;
		updateMeta(instanceId);
		return safeGet(instances, instanceId);
	};

	const release = (instanceId: AudioPlayerInstanceId): void => {
		console.debug('createAudioPlayer release');
		safeGet(metas, instanceId).attachCount--;
		updateMeta(instanceId);
	};

	const destroy = (instanceId: AudioPlayerInstanceId): void => {
		console.debug('createAudioPlayer destroy');
		if (safeGet(metas, instanceId).attachCount > 0) {
			throw new Error(
				`Cannot destroy AudioPlayerInstance (id: ${instanceId}) while it's attached to a component (attachCount: ${
					safeGet(metas, instanceId).attachCount
				})`,
			);
		}

		if (safeGet(metas, instanceId).isActiveInstance) {
			(async () => {
				const player = await getPlayer();
				await player.stopAsync();
			})();
		}

		updateMeta(instanceId);

		delete instances[instanceId];
		delete metas[instanceId];
		delete statuses[instanceId];
		delete progresses[instanceId];
	};

	return {
		attach,
		release,
		destroy,
	};
};

function hasShallowChange<T extends Record<string, unknown>>(
	current: T,
	next: T,
) {
	return Object.keys(next).some((key) => current[key] !== next[key]);
}

// https://stackoverflow.com/a/66807465
const isNonNullable = <T>(x: T): x is NonNullable<T> => x != null;

const safeGet = <T extends Record<string, unknown>, K extends keyof T>(
	obj: T,
	key: K,
) => {
	const val = obj[key];
	if (isNonNullable(val)) return val;
	throw new Error(`Cannot get ${String(key)} from ${obj}`);
};

export default createAudioPlayer;

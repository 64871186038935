import { HelpButton } from '@wearemojo/ui-components';

import useAppIntercom from '../hooks/useAppIntercom';
import { PresentIntercomParams } from '../utils/analytics/intercom.types';

type Props = {
	context: string;
	type: 'support';
};

const IntercomButton = ({ context, type }: Props) => {
	const { unreadMessagesCount, presentIntercom } = useAppIntercom();

	const onPress = () => {
		const payload: PresentIntercomParams = { context, type: 'support' };

		presentIntercom(payload);
	};

	if (type !== 'support') {
		throw new Error(`Unsupported help type: ${type}`);
	}

	return <HelpButton onPress={onPress} hasActivity={!!unreadMessagesCount} />;
};

export default IntercomButton;

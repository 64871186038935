import cookie from 'js-cookie';

import { setApexCookie } from './domainCookies';

const COOKIE_NAME = 'mojo_device_vendor_id';

export async function getVendorId(): Promise<string> {
	const current = cookie.get(COOKIE_NAME);

	if (current) {
		return current;
	}

	const buf = new Uint8Array(16);

	window.crypto.getRandomValues(buf);

	const hex = Array.from(buf)
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('');

	setApexCookie(COOKIE_NAME, hex, {
		expires: 365,
		secure: true,
		sameSite: 'lax',
	});

	return hex;
}

export async function requestTrackingPermissions(): Promise<boolean> {
	return false;
}

export async function getTrackingPermissions(): Promise<boolean> {
	return false;
}

export function getAdvertisingId(): string | null {
	return null;
}

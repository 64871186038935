import { BlockContentType, DayComplete } from '@wearemojo/sanity-schema';
import { HomeDayCompleteView } from '@wearemojo/ui-components';

import ModalManager from '../ModalManager';

type Props = {
	type: DayComplete['type'];
	title: string;
	content: BlockContentType;
};

const HomeDayCompleteModal = ({ type, title, content }: Props) => {
	const { closeModal } = ModalManager.useModal();

	return (
		<HomeDayCompleteView
			type={type}
			title={title}
			content={content}
			onClosePress={closeModal}
		/>
	);
};

export default HomeDayCompleteModal;

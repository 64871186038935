import { ServiceParams } from '@wearemojo/api-client';
import cookie from 'js-cookie';
import { useEffect } from 'react';

import { HandleableErrorCode } from '../errors/HandleableError';
import useEndpointMutation from '../hooks/queries/useEndpointMutation';
import { useAppSelector } from '../hooks/useAppSelector';
import ModalManager from '../ModalManager';
import api from '../store/api';
import useSignupAttributionStatus from '../store/api/hooks/useSignupAttributionStatus';
import { selectUserId } from '../store/session';
import { logger } from '../utils/logging';
import { AnalyticsEvent } from '.';
import { CookieChoices } from './attribution.types';
import { unsetCookieRecursive } from './domainCookies';
import { useTrackEvent } from './trackEvent';

// matches to attribution.js
// https://github.com/wearemojo/mojo/blob/main/static-assets/attribution.js
const landingDataCookieKey = 'mojo_attribution';

const cookieConsentCookieKey = 'mojo_cookie_consent';

// matches to attribution service schemas
const cookieKeys = [
	'_fbc',
	'_fbp',
	'_ga',
	'_ga_7CJXM010P6',
	'_gcl_au',
	'_gcl_aw',
	'dh_aid',
	'mojo_device_vendor_id',
];

export const useSignupAttribution = () => {
	const trackEvent = useTrackEvent();
	const userId = useAppSelector(selectUserId);
	const { status } = useSignupAttributionStatus().data ?? {};
	const [attributeSignup, { isSuccess }] = useEndpointMutation(
		api.endpoints.attributeSignup.useMutation(),
		{
			customHandleErrors: [HandleableErrorCode.user_signup_already_attributed],
		},
	);

	useEffect(() => {
		if (!userId || status !== 'awaiting_attribution') return;

		const headers = {
			'user-agent': window.navigator.userAgent,
		};

		const allCookies = cookie.get();
		const cookies = cookieKeys.reduce<Record<string, string>>((acc, key) => {
			const value = allCookies[key];
			if (!value) return acc;
			return { ...acc, [key]: value };
		}, {});

		const landingData = getLandingData();
		const cookieConsent = getCookieConsent();

		attributeSignup({
			userId,
			ipAddress: null,
			headers,
			cookies,
			cookieConsent,
			landingData,
		});
	}, [userId, status, attributeSignup, trackEvent]);

	const modalManager = ModalManager.useModal();
	useEffect(() => {
		if (!userId) {
			const allCookies = cookie.get();

			trackEvent(AnalyticsEvent.cookies_seen_in_app_at_startup, {
				keys: Object.keys(allCookies),
			});

			// wait a few moments just in case cookies take a while to be set
			setTimeout(() => {
				if (!getCookieConsent()) {
					trackEvent(AnalyticsEvent.cookie_consent_shown, {});
					modalManager.openModal('cookieConsent', { trackEvent });
				}
			}, 1000);
		}
	}, [modalManager, userId, trackEvent]);

	useEffect(() => {
		if (!isSuccess) return;

		unsetCookieRecursive(landingDataCookieKey);
	}, [isSuccess]);
};

const getLandingData =
	(): ServiceParams['attribution']['attributeSignup']['landingData'] => {
		const data = cookie.get(landingDataCookieKey);
		if (!data) return null;

		try {
			return JSON.parse(data);
		} catch {
			return null;
		}
	};

const getCookieConsent =
	(): ServiceParams['attribution']['attributeSignup']['cookieConsent'] => {
		const data = cookie.get(cookieConsentCookieKey);
		if (!data) {
			return null;
		}

		try {
			return JSON.parse(data);
		} catch (error) {
			logger.captureError('Error parsing cookie consent', { error });
			return null;
		}
	};

export const MOJO_COOKIE_CONSENT_VERSION: string | undefined = '2023-08-30';

export const setCookieConsent = (choices: CookieChoices) => {
	cookie.set(
		cookieConsentCookieKey,
		JSON.stringify({
			version: MOJO_COOKIE_CONSENT_VERSION,
			timestamp: new Date().toISOString(),
			choices,
		}),
	);
};

import Cher from '@wearemojo/cher';
import {
	ActionLink,
	ContentVariables,
	WhoopsError,
} from '@wearemojo/ui-components';
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { AnalyticsEvent, AnalyticsPayload } from '../analytics';
import useAppReload from '../hooks/useAppReload';
import ModalManager from '../ModalManager';
import { createLinkOnPress } from '../navigation/LinkProvider';
import { logger } from '../utils/logging';
import useErrorDefinitions from './useErrorDefinitions';
import { transformError } from './useErrorTransformer';

type Props = {
	children: ReactNode;
	/*
		Provider can be embedded multiple times at multiple layers, near the top of
		the tree some handlers may not be available, so we allow them to be optional
	*/
	errorTransformer?: (cher: Cher, options?: SetWhoopsOptions) => WhoopsError;
	onPressDismiss?: () => void;
	onPressSupport?: () => void;
};

const throwNotInitialized = () => {
	throw new Error(`Whoops context not initialized`);
};

export type SetWhoopsOptions = {
	contentVariables?: ContentVariables;
	extraActions?: ActionLink[];
	onDismissEffect?: () => void;
};

const WhoopsContext = createContext<{
	whoopsError: WhoopsError | null;
	setWhoopsError: (_cher: Cher | null, _options?: SetWhoopsOptions) => void;
	clearWhoopsError: () => void;
}>({
	whoopsError: null,
	setWhoopsError: () => throwNotInitialized(),
	clearWhoopsError: () => throwNotInitialized(),
});

const WhoopsProvider = ({
	children,
	errorTransformer,
	onPressDismiss,
	onPressSupport,
}: Props) => {
	const onPressReload = useAppReload();
	const [whoopsError, setError] = useState<{
		error: WhoopsError;
		options?: SetWhoopsOptions;
	} | null>(null);
	const fallbackErrorTransformer = useFallbackErrorTransformer();
	const resolvedErrorTransformer = errorTransformer ?? fallbackErrorTransformer;

	const setWhoopsError = useCallback(
		(cher: Cher | null, options?: SetWhoopsOptions) => {
			setError(
				cher && {
					error: resolvedErrorTransformer(cher, options),
					options,
				},
			);
		},
		[resolvedErrorTransformer],
	);
	const clearWhoopsError = useCallback(() => setError(null), []);
	const { openModal, closeModal } = ModalManager.useModal();

	const _onPressDismiss = useMemo(
		() =>
			onPressDismiss &&
			(() => {
				onPressDismiss();
				whoopsError?.options?.onDismissEffect?.();
			}),
		[onPressDismiss, whoopsError?.options],
	);

	useEffect(() => {
		if (whoopsError) {
			openModal(
				'whoops',
				{
					whoopsError: whoopsError.error,
					onPressDismiss: _onPressDismiss,
					onPressSupport,
				},
				{
					onDismiss: () => {
						clearWhoopsError();
					},
				},
			);
		} else {
			closeModal('whoops');
		}
	}, [
		_onPressDismiss,
		clearWhoopsError,
		closeModal,
		onPressDismiss,
		onPressReload,
		onPressSupport,
		openModal,
		whoopsError,
	]);

	const contextValue = useMemo(() => {
		return {
			whoopsError: whoopsError?.error ?? null,
			setWhoopsError,
			clearWhoopsError,
		};
	}, [whoopsError?.error, setWhoopsError, clearWhoopsError]);

	return (
		<WhoopsContext.Provider value={contextValue}>
			{children}
		</WhoopsContext.Provider>
	);
};

export const useWhoopsHandler = () => {
	return useContext(WhoopsContext).setWhoopsError;
};

/**
 * Fallback error transformer and actions are for higher up the app tree where
 * many other components (data resolvers, navigation handlers) are unavailable
 */
const useFallbackErrorTransformer = () => {
	const defs = useErrorDefinitions();
	const onPressReload = useAppReload();

	return useCallback(
		(
			error: Cher,
			{ extraActions = [], contentVariables }: SetWhoopsOptions = {},
		): WhoopsError =>
			transformError({
				defs,
				error,
				contentVariables,
				actions: [
					...extraActions,
					{
						title: 'Reload',
						linkProvider: createLinkOnPress(onPressReload),
					},
				],
				trackEvent: (eventName, payload) => {
					// Improve Sentry error messages by making sure that, if payload contains a `code`, it is present on the message.
					// i.e. fallback error event: error_needs_mapping [object Object] => fallback error event: error_needs_mapping request_timeout
					const code =
						typeof payload === 'object' && 'code' in payload
							? (payload.code as string)
							: undefined;

					if (shouldIgnoreWarning(eventName, payload)) return;
					logger.captureWarning(
						`fallback error event: ${eventName}${code ? ` ${code}` : ''}`,
						payload,
					);
				},
			}),
		[defs, onPressReload],
	);
};

/**
 * Helper function with all the warnings we want to manually ignore.
 */
function shouldIgnoreWarning<
	Event extends AnalyticsEvent,
	Payload extends AnalyticsPayload[Event],
>(eventName: Event, payload: Payload) {
	if (eventName === AnalyticsEvent.error_presented) {
		if (
			typeof payload === 'object' &&
			'meta' in payload &&
			(payload?.meta as any)?.error?.name === 'AbortError'
		) {
			return true;
		}
	}

	return false;
}

export default WhoopsProvider;

import { ApiClient } from '@wearemojo/api-client';
import { createContext, useContext } from 'react';

export const ApiClientContext = createContext<ApiClient | undefined>(undefined);

export const useApiClient = () => {
	const apiClient = useContext(ApiClientContext);
	if (apiClient) return apiClient;

	throw new Error('ApiClientContext is not set');
};

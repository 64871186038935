import { createModalManager } from '@wearemojo/modal-manager';
import {
	AbstractModal,
	CommunityOnboardingModal,
	ExercisePelvicFloorConfirmationModalView,
	PopupInfo,
} from '@wearemojo/ui-components';

import CookieConsentModal from './modals/CookieConsentModal';
import DeleteAccountModal from './modals/DeleteAccountModal';
import FreeUserFirstCourseCompleteModal from './modals/FreeUserFirstCourseCompleteModal';
import HomeDayCompleteModal from './modals/HomeDayCompleteModal';
import MagicLinkApprovalModal from './modals/MagicLinkApprovalModal';
import MagicLinkRequesterModal from './modals/MagicLinkRequesterModal';
import RegisterPrefaceExitModal from './modals/RegisterPrefaceExitModal';
import WhoopsModal from './modals/WhoopsModal';

const ModalManager = createModalManager({
	modals: {
		whoops: {
			component: WhoopsModal,
		},
		deleteAccount: {
			component: DeleteAccountModal,
		},
		homeDayComplete: {
			component: HomeDayCompleteModal,
		},
		freeUserFirstCourseComplete: {
			component: FreeUserFirstCourseCompleteModal,
		},
		magicLinkApproval: {
			component: MagicLinkApprovalModal,
		},
		magicLinkRequester: {
			component: MagicLinkRequesterModal,
		},
		restorePurchases: {
			component: PopupInfo,
		},
		cookieConsent: {
			component: CookieConsentModal,
		},
		communityOnboarding: {
			component: CommunityOnboardingModal,
		},
		exercisePelvicFloorConfirmation: {
			component: ExercisePelvicFloorConfirmationModalView,
		},
		exitRegisterPreface: {
			component: RegisterPrefaceExitModal,
		},
		kickstartChallenge: {
			component: AbstractModal,
		},
		lockedContent: {
			component: AbstractModal,
		},
	},
});

export default ModalManager;

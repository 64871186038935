import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RegisterPrefaceExitView } from '@wearemojo/ui-components';

import { AnalyticsEvent, TrackEventHook } from '../analytics';
import { FragmentType } from '../content/FragmentType';
import { useFragment } from '../hooks/content/useFragment';
import ModalManager from '../ModalManager';
import { RootParamList } from '../navigation/params';
import ScreenKey from '../navigation/ScreenKey';

const RegisterPrefaceExitModal = ({
	trackEvent,
	navigation,
}: {
	trackEvent: ReturnType<TrackEventHook>;
	navigation: NativeStackNavigationProp<RootParamList>;
}) => {
	const { closeModal } = ModalManager.useModal();

	const { title, skipCta, exitCta, cancelCta } = useFragment(
		FragmentType.RegisterPrefaceExitModal,
	);

	const onSkipPress = () => {
		trackEvent(AnalyticsEvent.register_preface_skip_button_pressed, {});
		navigation.navigate(ScreenKey.Auth, { mode: 'register' });
		closeModal();
	};

	const onExitPress = () => {
		trackEvent(AnalyticsEvent.register_preface_exit_button_pressed, {});
		navigation.navigate(ScreenKey.AuthMobileIntro, undefined);
		closeModal();
	};

	const onCancelPress = () => {
		trackEvent(AnalyticsEvent.register_preface_cancel_button_pressed, {});
		closeModal();
	};

	return (
		<RegisterPrefaceExitView
			title={title}
			skipCta={skipCta}
			onSkipPress={onSkipPress}
			exitCta={exitCta}
			onExitPress={onExitPress}
			cancelCta={cancelCta}
			onCancelPress={onCancelPress}
		/>
	);
};

export default RegisterPrefaceExitModal;

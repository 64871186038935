import { useEffect, useRef, useState } from 'react';

import { logger } from '../utils/logging';

const DEFAULT_READY_TIMEOUT_MS = 5000;

type LoadingSignals = Record<string, boolean>;

type Options = {
	timeoutMs?: number;
};

const useIsReadyTimeout = (
	loadingSignals: LoadingSignals,
	{ timeoutMs = DEFAULT_READY_TIMEOUT_MS }: Options = {},
) => {
	const isLoading = Object.values(loadingSignals).some(Boolean);
	const [isReady, setIsReady] = useState(!isLoading);
	if (!isLoading && !isReady) {
		setIsReady(true);
	}

	const signals = useRef(loadingSignals);
	signals.current = loadingSignals;

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsReady((ready) => {
				if (!ready) {
					logger.captureError(
						`useIsReadyTimeout: timeout of ${timeoutMs}ms exceeded ${JSON.stringify(
							{ loadingSignals: signals.current },
						)}`,
					);
				}
				return true;
			});
		}, timeoutMs);
		return () => clearTimeout(timeout);
	}, [timeoutMs]);

	/*
		@TODO: if timeout is exceeded, track how long it takes to resolve, be sure
		not to allow the following cavets to break/reset the timeout fallback:
		- some loading signals might never resolve
		- individual loading signals might flip back to false
	*/

	return isReady;
};

export default useIsReadyTimeout;

import { themeColors, UITheme } from '@wearemojo/ui-constants';
import { StatusBar } from 'expo-status-bar';
import * as SystemUI from 'expo-system-ui';
import { useEffect } from 'react';
import changeNavigationBarColor from 'react-native-navigation-bar-color';

import useGlobalStyles from './hooks/useGlobalStyles';

const StyleEffects = () => {
	useGlobalStyles();

	useEffect(() => {
		SystemUI.setBackgroundColorAsync(
			themeColors[UITheme.dark].background_secondary,
		);
		changeNavigationBarColor('transparent', true);
	}, []);

	return <StatusBar style="light" />;
};

export default StyleEffects;

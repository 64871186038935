import { ContextUtil } from '@wearemojo/ui-components';
import { UIContext } from '@wearemojo/ui-components/src/utils/types';
import { useEffect, useMemo, useState } from 'react';
import { Dimensions, ScaledSize, useColorScheme } from 'react-native';
import { UnistylesRuntime } from 'react-native-unistyles';

import { selectUiFlagOverrides } from '../store/dev';
import { selectTheme } from '../store/settings';
import { logger } from '../utils/logging';
import platform from '../utils/platform';
import { useAccessibilityInfo } from './useAccessibilityInfo';
import { useAppSelector } from './useAppSelector';

export default function (): UIContext {
	const themeSetting = useAppSelector(selectTheme);
	const systemTheme = ContextUtil.getTheme(useColorScheme());
	const theme = themeSetting === 'system' ? systemTheme : themeSetting;
	const flagOverrides = useAppSelector(selectUiFlagOverrides);
	const formFactor = useFormFactor();
	const {
		reduceTransparencyEnabled: reduceTransparency = false,
		reduceMotionEnabled: reduceMotion = false,
		prefersCrossFadeTransitions,
	} = useAccessibilityInfo();

	const flags = useMemo(() => {
		return {
			...ContextUtil.flagDefaults,
			...flagOverrides,
		};
	}, [flagOverrides]);

	return useMemo(() => {
		console.debug('useCreateUIContext memo');
		try {
			UnistylesRuntime.setTheme(theme);
		} catch (error) {
			logger.captureError('UnistylesRuntime.setTheme error', { error, theme });
		}
		const defaults = ContextUtil.createUIContext();
		return {
			theme,
			platform: platform ?? defaults.platform,
			formFactor,
			flags,
			reduceTransparency,
			reduceMotion,
			prefersCrossFadeTransitions,
		};
	}, [
		theme,
		formFactor,
		flags,
		reduceTransparency,
		reduceMotion,
		prefersCrossFadeTransitions,
	]);
}

const useFormFactor = () => {
	/*
		Could use useWindowDimensions here, but that would result in a huge amount
		of unnecessary render cycles, as we just want certain breakpoints
	*/
	const [formFactor, setFormFactor] = useState(() =>
		ContextUtil.getFormFactor(Dimensions.get('window')),
	);

	useEffect(() => {
		function update({ window }: { window: ScaledSize }) {
			setFormFactor(ContextUtil.getFormFactor(window));
		}
		const subscription = Dimensions.addEventListener('change', update);
		update({ window: Dimensions.get('window') });

		return () => subscription.remove();
	}, []);

	return formFactor;
};

import { skipToken } from '@reduxjs/toolkit/query';
import * as Application from 'expo-application';
import { Platform } from 'react-native';

import api from '../../store/api';
import { selectUserId } from '../../store/session';
import useEndpointQuery from '../queries/useEndpointQuery';
import { useAppSelector } from '../useAppSelector';

const useGetRevenuecatConfig = () => {
	const userId = useAppSelector(selectUserId);
	const platform = Platform.OS;

	const isValidPlatform = platform === 'ios';
	const platformToUse = isValidPlatform ? platform : undefined;

	const applicationId = Application.applicationId;
	const { data, isLoading } = useEndpointQuery(
		api.endpoints.getRevenuecatConfig.useQuery(
			userId && applicationId && platformToUse
				? { userId, applicationId, platform: platformToUse }
				: skipToken,
		),
	);

	return { data, isLoading };
};

export default useGetRevenuecatConfig;

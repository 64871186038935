import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Cher from '@wearemojo/cher';
import { useCallback, useContext, useState } from 'react';
import { Platform } from 'react-native';
import Purchases from 'react-native-purchases';

import { AnalyticsEvent } from '../analytics';
import { useTrackEvent } from '../analytics/trackEvent';
import { useApiClient } from '../api';
import { AppInstanceContext } from '../components/AppInstance';
import { useWhoopsHandler } from '../errors/WhoopsProvider';
import NavigatorKey from '../navigation/NavigatorKey';
import { RootParamList } from '../navigation/params';
import { endLogRocketSession } from '../services/logrocket';
import { SecureStoreLoggedInState } from '../storage/loginState';
import { resetStore } from '../store';
import { logger } from '../utils/logging';
import useAppDispatch from './useAppDispatch';

const useOnLogout = () => {
	const apiClient = useApiClient();
	const appInstance = useContext(AppInstanceContext);
	const navigation = useNavigation<NativeStackNavigationProp<RootParamList>>();
	const trackEvent = useTrackEvent();
	const dispatch = useAppDispatch();
	// @TODO: ideally should be a shared global state
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const whoops = useWhoopsHandler();

	const onLogout = useCallback(() => {
		trackEvent(AnalyticsEvent.logged_out, {});
		const logout = async () => {
			console.debug('AccountScreen: logging out');

			await SecureStoreLoggedInState.clearIsLoggedIn();

			if (Platform.OS === 'ios') {
				if (await Purchases.isConfigured()) {
					await Purchases.logOut();
				}
			}

			await endLogRocketSession();

			await apiClient.remakeSession({ code: 'logged_out' });
			navigation.reset({
				index: 0,
				routes: [
					{
						name: NavigatorKey.MainNavigator,
					},
				],
			});
			console.debug('AccountScreen: resetting store');
			const start = Date.now();
			dispatch(resetStore());
			const duration = Date.now() - start;
			if (duration > 100) {
				logger.captureWarning(`AccountScreen: slow store reset`, {
					duration: `${duration}ms`,
				});
			}
			console.debug('AccountScreen: reloading app');
			appInstance.reload();
		};
		setIsLoggingOut(true);
		logout()
			.catch((error) => {
				logger.captureWarning('AccountScreen: failed to log out', error);
				whoops(Cher.parseOrWrap(error, 'failed_to_log_out'));
			})
			.finally(() => setIsLoggingOut(false));
	}, [trackEvent, apiClient, navigation, dispatch, appInstance, whoops]);

	return {
		onLogout,
		isLoggingOut,
	};
};

export default useOnLogout;

import cookie, { CookieAttributes } from 'js-cookie';

import { logger } from '../utils/logging';

type CookieOptions = Pick<CookieAttributes, 'expires' | 'secure' | 'sameSite'>;

export const setApexCookie = (
	name: string,
	value: string,
	options?: CookieOptions,
) => {
	cookie.set(name, value, {
		...options,
		domain: `.${findDomainApex() ?? window.location.hostname}`,
		path: '/',
	});
};

export const unsetCookieRecursive = (name: string) => {
	const hostnameLevels = generateHostnameLevels();

	for (const hostname of hostnameLevels) {
		cookie.remove(name, {
			domain: `.${hostname}`,
			path: '/',
		});

		if (!cookie.get(name)) return;
	}

	logger.captureWarning(`Could not unset cookie ${name}`);
};

const findDomainApex = () => {
	const hostnameLevels = generateHostnameLevels();
	const cookieName = `_mojo_apex_${Date.now()}`;

	for (const hostname of hostnameLevels) {
		cookie.set(cookieName, '1', {
			domain: `.${hostname}`,
			path: '/',
		});

		if (cookie.get(cookieName)) {
			cookie.remove(cookieName, {
				domain: `.${hostname}`,
				path: '/',
			});

			return hostname;
		}
	}

	logger.captureWarning('Could not find domain apex');
};

const generateHostnameLevels = () => {
	const hostnameLabels = window.location.hostname.split('.');

	return hostnameLabels.map((_, i, a) => a.slice(i).join('.')).reverse();
};

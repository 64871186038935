import { useEvaluateFeature } from './useEvaluateFeature';

export const useFeature = <T>(
	featureKey: string,
): {
	isFetching: boolean;
	isLoading: boolean;
	value: T | null | undefined;
	isOn: boolean | undefined;
} => {
	const { evaluateFeature, isFetching, isLoading } = useEvaluateFeature();

	const { value, isOn } = evaluateFeature<T>(featureKey);

	return {
		isFetching,
		isLoading,
		value,
		isOn,
	};
};

import api from '../store/api';
import useEndpointQuery from './queries/useEndpointQuery';

const useIsAppReady = () => {
	const { isLoading: isFragmentsLoading } = useEndpointQuery(
		api.endpoints.getFragments.useQuery(),
	);

	return !isFragmentsLoading;
};

export default useIsAppReady;

import { SerializedError } from '@reduxjs/toolkit';
import Cher from '@wearemojo/cher';
import { useMemo } from 'react';

import {
	HandleableError,
	HandleableErrorCode,
} from '../../errors/HandleableError';
import useErrorHandler from '../../errors/useErrorHandler';

export type ErrorReturn<CustomHandleError extends HandleableErrorCode> =
	CustomHandleError extends never
		? undefined
		: HandleableError<CustomHandleError> | undefined;

export type OverrideReturnError<
	Return,
	CustomHandleError extends HandleableErrorCode,
> = Omit<Return, 'error'> & {
	error: ErrorReturn<CustomHandleError>;
};

const useEndpointQuery = <
	Result extends { error?: Cher | SerializedError },
	CustomHandleError extends HandleableErrorCode = never,
>(
	result: Result,
	{
		customHandleErrors = [],
		onDismissEffect,
	}: {
		customHandleErrors?: CustomHandleError[];
		onDismissEffect?: () => void;
	} = {},
): OverrideReturnError<Result, CustomHandleError> => {
	const cherError = useMemo(
		() => result.error && Cher.parseOrWrap(result.error, 'unknown_query_error'),
		[result.error],
	);

	const customHandled =
		cherError &&
		customHandleErrors.includes(cherError.code as CustomHandleError);

	const whoopsError = customHandled ? undefined : cherError;
	const resultError = (
		customHandled ? cherError : undefined
	) as ErrorReturn<CustomHandleError>;

	const whoopsOptions = useMemo(() => ({ onDismissEffect }), [onDismissEffect]);

	useErrorHandler(whoopsError, whoopsOptions);

	return { ...result, error: resultError };
};

export default useEndpointQuery;

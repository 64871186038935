import * as Network from 'expo-network';
import { useEffect, useState } from 'react';

import { logger } from '../utils/logging';

export enum NetworkStatus {
	online = 'online',
	offline = 'offline',
	pending = 'pending',
}

const useNetworkStatus = () => {
	const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(
		NetworkStatus.pending,
	);
	const [isResolved, setIsResolved] = useState(false);
	useEffect(() => {
		const check = async () => {
			try {
				const networkState = await Network.getNetworkStateAsync();
				if (networkState.isInternetReachable) {
					setNetworkStatus(NetworkStatus.online);
				} else {
					setNetworkStatus(NetworkStatus.offline);
				}
			} catch (e) {
				setNetworkStatus(NetworkStatus.offline);
				logger.captureException(e);
			} finally {
				setIsResolved(true);
			}
		};
		if (!isResolved) {
			check();
		}
	}, [isResolved]);

	return { networkStatus, retryConnection: () => setIsResolved(false) };
};

export default useNetworkStatus;

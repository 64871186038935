import { createClient } from '@sanity/client';
import { LoadingIndicator } from '@wearemojo/ui-components';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { defaultSanityConfig } from '../../environment';
import { useAppSelector } from '../hooks/useAppSelector';
import api from '../store/api';
import ApiClients, { ApiClient } from '../store/api/ApiClients';
import { selectSanityConfig } from '../store/dev';

const ApiGate = ({ children }: { children: ReactNode }) => {
	const [isReady, setIsReady] = useState(false);
	const clientSdkConfig = useAppSelector(
		useMemo(() => api.endpoints.getClientSdkConfig.select(), []),
	).data;

	const sanityConfig = useAppSelector(selectSanityConfig);

	useEffect(() => {
		const config = sanityConfig?.projectId
			? sanityConfig
			: (clientSdkConfig?.sanity ?? defaultSanityConfig);

		const sanityClient = createClient({
			apiVersion: '2022-02-09',
			/*
				Reduce request timeout to avoid warning about long lived timers on Android:
				https://github.com/facebook/react-native/issues/12981
			*/
			timeout: 60 * 1000,
			...config,
		});
		ApiClients.setClient(ApiClient.sanity, sanityClient);

		setIsReady(true);
	}, [clientSdkConfig, sanityConfig]);

	if (!isReady) {
		return <LoadingIndicator label="Other API clients" />;
	}

	return <>{children}</>;
};

export default ApiGate;

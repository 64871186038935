import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { skipToken } from '@reduxjs/toolkit/query';
import { AuthSession, RemakeSessionReason } from '@wearemojo/api-client';
import Cher from '@wearemojo/cher';
import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';

import { useApiClient } from './api';
import { registerAudio } from './audio';
import { HandleableErrorCode } from './errors/HandleableError';
import { useSentryIdentify } from './errors/sentry';
import { useWhoopsHandler } from './errors/WhoopsProvider';
import useEndpointQuery from './hooks/queries/useEndpointQuery';
import useAppDispatch from './hooks/useAppDispatch';
import { useAppSelector } from './hooks/useAppSelector';
import useCleanupOnceKeys from './hooks/useCleanupOnceKeys';
import useIsNavigationReady from './hooks/useIsNavigationReady';
import useOnAppFocus, { useOnAppFocusFirstActive } from './hooks/useOnAppFocus';
import useProcessLoggedOutEvents from './hooks/useProcessLoggedOutEvents';
import usePseudonym from './hooks/usePseudonym';
import { RootParamList } from './navigation/params';
import ScreenKey from './navigation/ScreenKey';
import { useSetupNotifications } from './notifications';
import {
	initAppsFlyer,
	registerAppsFlyerListeners,
} from './services/appsflyer';
import useLogRocket from './services/logrocket';
import api from './store/api';
import ApiTag from './store/api/ApiTag';
import { selectUserId, setSessionId, setUserId } from './store/session';
import * as BadgeBGTask from './tasks/badge';
import { logger } from './utils/logging';

const {
	getAuthUser,
	getEpisodeStates,
	isStaffMember,
	getUserPreferences,
	getSubscriptionStatus,
} = api.endpoints;

const AppEffects = () => {
	const navigation = useNavigation<NativeStackNavigationProp<RootParamList>>();
	const isNavReady = useIsNavigationReady();

	const dispatch = useAppDispatch();
	const apiClient = useApiClient();
	const userId = useAppSelector(selectUserId);

	useSetupNotifications();
	useLogRocket();
	useSentryIdentify();

	useCleanupOnceKeys();
	useProcessLoggedOutEvents();

	const onAppFocus = useCallback(() => {
		dispatch(api.internalActions.onFocus());
	}, [dispatch]);
	useOnAppFocus(onAppFocus);

	// On Android we need to register audio only if app is active
	const registerAudioOnAndroid = useCallback(() => {
		if (Platform.OS === 'android') {
			registerAudio();
		}
	}, []);
	useOnAppFocusFirstActive(registerAudioOnAndroid);

	const userIdParams = userId ? { userId } : skipToken;
	const pseudonym = usePseudonym();
	useEndpointQuery(getAuthUser.useQuery(userIdParams));
	useEndpointQuery(getEpisodeStates.useQuery(userIdParams));
	useEndpointQuery(isStaffMember.useQuery(userIdParams));
	useEndpointQuery(getUserPreferences.useQuery(userIdParams));
	useEndpointQuery(
		getSubscriptionStatus.useQuery(
			userId ? { userId, forceRefresh: false } : skipToken,
		),
		{ customHandleErrors: [HandleableErrorCode.user_not_subscribed] },
	);

	const whoops = useWhoopsHandler();

	useEffect(() => {
		const onAuthUser = (authUser?: Readonly<AuthSession['User']>) => {
			console.debug('authUser emitted', { userId: authUser?.userId });
			dispatch(api.util.invalidateTags([ApiTag.FeatureConfig]));
			dispatch(setUserId(authUser?.userId));
		};
		const onAuthSession = (authSession: Readonly<AuthSession['Session']>) => {
			console.debug('authSession emitted', {
				sessionId: authSession.sessionId,
			});
			dispatch(setSessionId(authSession.sessionId));
		};
		const onAuthError = (reason: RemakeSessionReason) => {
			console.log('authError emitted', reason);
			if (['logged_out', 'session_abandoned'].includes(reason.code)) {
				return;
			}
			logger.captureException(reason);
			whoops(new Cher(reason.code, reason.meta));
		};

		onAuthUser(apiClient.getAuthUser());
		onAuthSession(apiClient.getAuthSession());
		apiClient.events.on('authUser', onAuthUser);
		apiClient.events.on('authSession', onAuthSession);
		apiClient.events.on('authError', onAuthError);

		return () => {
			apiClient.events.off('authUser', onAuthUser);
			apiClient.events.off('authSession', onAuthSession);
			apiClient.events.off('authError', onAuthError);
		};
	}, [apiClient, dispatch, whoops]);

	useEffect(() => {
		if (
			isNavReady &&
			pseudonym.error?.code === HandleableErrorCode.user_not_consented
		) {
			navigation.navigate(ScreenKey.Consent);
		}
	}, [isNavReady, navigation, pseudonym.error?.code]);

	useEffect(() => {
		(async () => {
			try {
				if (userId) {
					// only register the badge task if it is not activity delivery variant
					// TODO: do we want this back for activity delivery?
					// await BadgeBGTask.registerBackgroundFetchAsync();
				} else {
					await BadgeBGTask.unregisterBackgroundFetchAsync();
				}
			} catch (error) {
				logger.captureError(
					'Error registering/unregistering background fetch',
					{ error },
				);
			}
		})();
	}, [userId]);

	useEffect(() => {
		const unregisterListeners = registerAppsFlyerListeners();

		const shouldAskATT = Platform.OS === 'ios';
		initAppsFlyer(shouldAskATT);

		return () => unregisterListeners();
	}, []);

	return null;
};

export default AppEffects;

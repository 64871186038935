import { useEffect } from 'react';

import {
	HandleableError,
	HandleableErrorCode,
} from '../../errors/HandleableError';
import { useWhoopsHandler } from '../../errors/WhoopsProvider';
import { selectImpersonatedUserId } from '../../store/session';
import { useAppSelector } from '../useAppSelector';

const useHandleImpersonationAccessDenied = <T extends HandleableErrorCode>(
	error?: HandleableError<T>,
) => {
	const whoops = useWhoopsHandler();
	const isImpersonating = !!useAppSelector(selectImpersonatedUserId);

	useEffect(() => {
		if (error?.code !== HandleableErrorCode.access_denied) return;

		if (
			!isImpersonating ||
			!error.reasons?.every((r) => r.code === 'user_id_mismatch')
		) {
			whoops(error);
		}
	}, [error, isImpersonating, whoops]);
};

export default useHandleImpersonationAccessDenied;

import * as ScreenCapture from 'expo-screen-capture';
import { useEffect } from 'react';

import { AnalyticsEvent, AnalyticsPayload } from '.';
import { useTrackEvent } from './trackEvent';

const useTrackScreenshot = () => {
	const trackEvent = useTrackEvent();
	const [status] = ScreenCapture.usePermissions();

	useEffect(() => {
		let subscription: ScreenCapture.Subscription;
		if (status?.granted) {
			subscription = ScreenCapture.addScreenshotListener(() => {
				trackEvent(
					AnalyticsEvent.screenshot_taken,
					{} as AnalyticsPayload[AnalyticsEvent.screenshot_taken],
				);
			});
		}
		return () => subscription?.remove();
	}, [status, trackEvent]);
};

export default useTrackScreenshot;

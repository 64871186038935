import { useEffect } from 'react';

import useCompleteActivity from '../store/api/hooks/useCompleteActivity';
import {
	resetLoggedOutEvents,
	selectLoggedOutEvents,
} from '../store/loggedOutEvents';
import { selectUserId } from '../store/session';
import useAppDispatch from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const useProcessLoggedOutEvents = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const events = useAppSelector(selectLoggedOutEvents);

	const { recordActivityCompleted } = useCompleteActivity();

	useEffect(() => {
		if (!userId) return;

		events.forEach((event) => {
			if (event.action === 'recordActivityCompleted') {
				recordActivityCompleted(
					event.data.payload,
					event.data.analyticsPayload,
				);
			}
		});

		dispatch(resetLoggedOutEvents());
	}, [dispatch, events, recordActivityCompleted, userId]);
};

export default useProcessLoggedOutEvents;

import { ApprovalCode } from '@wearemojo/api-client';
import {
	HelpButton,
	MagicLinkApprovalFlow,
	MagicLinkApprovalView,
	ModalHeader,
} from '@wearemojo/ui-components';
import { useCallback, useEffect, useState } from 'react';

import { AnalyticsEvent, TrackEventHook } from '../analytics';
import { FragmentType } from '../content/FragmentType';
import { useFragment } from '../hooks/content/useFragment';

export default ({
	onPressDismiss,
	onPressSupport,
	onApproveMagicLink,
	onRejectMagicLink,
	approvalCode,
	trackEvent,
}: {
	onPressDismiss?: () => void;
	onPressSupport?: () => void;
	onApproveMagicLink: () => Promise<ApprovalCode | undefined>;
	onRejectMagicLink: () => Promise<void>;
	approvalCode?: string;
	trackEvent: ReturnType<TrackEventHook>;
}) => {
	const magicLinkApprovalFragment = useFragment(FragmentType.MagicLinkApproval);

	const [flow, setFlow] = useState<MagicLinkApprovalFlow>('asking_approval');
	const [code, setCode] = useState('');
	const [approving, setApproving] = useState(false);
	const [rejecting, setRejecting] = useState(false);

	const onApprovalCode = useCallback(
		(_code: string) => {
			setCode(_code);
			setFlow('displaying_code');
			trackEvent(AnalyticsEvent.auth_approval_code, {});
		},
		[trackEvent],
	);

	useEffect(() => {
		if (approvalCode) {
			onApprovalCode(approvalCode);
		}
	}, [approvalCode, onApprovalCode]);

	useEffect(() => {
		trackEvent(AnalyticsEvent.auth_approval_presented, {});
	}, [trackEvent]);

	const onApprovePress = useCallback(async () => {
		setApproving(true);
		const _approvalCode = await onApproveMagicLink();
		if (_approvalCode?.approvalCode) {
			onApprovalCode(_approvalCode.approvalCode);
			setApproving(false);
		}
	}, [onApproveMagicLink, onApprovalCode]);

	const onRejectPress = useCallback(async () => {
		setRejecting(true);
		await onRejectMagicLink();
		setFlow('access_revoked');
		trackEvent(AnalyticsEvent.auth_approval_revoked, {});
		setRejecting(false);
	}, [onRejectMagicLink, trackEvent]);

	const navHeader = (
		<ModalHeader
			noBorder
			onPressClose={onPressDismiss}
			supportButton={onPressSupport && <HelpButton onPress={onPressSupport} />}
		/>
	);

	return (
		<MagicLinkApprovalView
			navHeader={navHeader}
			onPressSupport={onPressSupport}
			magicLinkApprovalFragment={magicLinkApprovalFragment}
			flow={flow}
			code={code}
			approving={approving}
			onApprovePress={onApprovePress}
			rejecting={rejecting}
			onRejectPress={onRejectPress}
		/>
	);
};

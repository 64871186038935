import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useCallback, useRef } from 'react';

import { RootParamList } from '../navigation/params';
import { resetOrPopIfPreviousRoute } from '../utils/navigation';

export default function useNavigationRedirect(
	screen: keyof RootParamList,
	params: any /* @TODO: resolve params */,
) {
	const navigation = useNavigation<NativeStackNavigationProp<RootParamList>>();

	// params is usually an unstable object, so lets save in a ref so it doesn't cause re-memoizations
	const refParams = useRef(params);
	refParams.current = params;

	const go = useCallback(() => {
		resetOrPopIfPreviousRoute(navigation, screen, refParams.current);
	}, [navigation, screen]);

	return go;
}

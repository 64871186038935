import Cher from '@wearemojo/cher';
import {
	ActionLink,
	ContentVariables,
	WhoopsError,
} from '@wearemojo/ui-components';
import { useCallback } from 'react';

import { AnalyticsEvent } from '../analytics';
import { useTrackEvent } from '../analytics/trackEvent';
import { AnalyticsProviderName } from '../analytics/types';
import useTransformActionToLinkProvider from '../hooks/cms/useTransformActionToLinkProvider';
import ModalManager from '../ModalManager';
import { createLinkOnPress } from '../navigation/LinkProvider';
import { logger } from '../utils/logging';
import { stripCher } from './stripCher';
import useErrorDefinitions, { ErrorDefinitions } from './useErrorDefinitions';
import { SetWhoopsOptions } from './WhoopsProvider';

const useErrorTransformer = (options?: { onPressEffect?: () => void }) => {
	const trackEvent = useTrackEvent();
	const defs = useErrorDefinitions();
	const transformAction = useTransformActionToLinkProvider(options);
	const { closeModal } = ModalManager.useModal();

	return useCallback(
		(
			error: Cher,
			{
				extraActions = [],
				contentVariables,
				onDismissEffect,
			}: SetWhoopsOptions = {},
		): WhoopsError => {
			const rootDef = defs.errors[error.code] ?? defs.defaultError;
			const resolvedActions = [
				...extraActions,
				...rootDef.actions
					.map((action) => transformAction(action))
					.flatMap((action) => action ?? []),
			];
			const dismissAction = {
				title: 'Dismiss',
				linkProvider: createLinkOnPress(() => {
					onDismissEffect?.();
					closeModal();
				}),
			};
			const actions = resolvedActions.length
				? resolvedActions
				: [dismissAction];
			return transformError({
				trackEvent,
				defs,
				error,
				contentVariables,
				actions,
			});
		},
		[closeModal, defs, trackEvent, transformAction],
	);
};

export const transformError = ({
	trackEvent,
	defs,
	error,
	contentVariables,
	actions,
}: {
	trackEvent: ReturnType<typeof useTrackEvent>;
	defs: ErrorDefinitions;
	error: Cher;
	contentVariables?: ContentVariables;
	actions: ActionLink[];
}) => {
	const rootDef = getErrorDefinition(defs, error, trackEvent);

	const type = rootDef.type;

	trackEvent(
		AnalyticsEvent.error_presented,
		{ ...error },
		(provider, payload) => {
			if (provider === AnalyticsProviderName.Intercom) {
				return payload;
			}

			return stripCher(error);
		},
	);

	const title = {
		content: rootDef.title,
		contentVariables: { ...contentVariables, error },
	};

	const messages = [error, ...(error.reasons || [])].map((e) => {
		const def = getErrorDefinition(defs, e, trackEvent);

		return {
			content: def.message,
			contentVariables: { ...contentVariables, error: e },
		};
	});

	return {
		title,
		messages,
		actions,
		error,
		type,
	};
};

const getErrorDefinition = (
	defs: ErrorDefinitions,
	error: Cher,
	trackEvent: ReturnType<typeof useTrackEvent>,
) => {
	const def = defs.errors[error.code];
	if (def) return def;

	// avoid tracking "error_needs_mapping" if errors haven't loaded
	if (Object.keys(defs.errors).length > 0) {
		logger.captureWarning(`CHER code '${error.code}' is not mapped`, { error });

		trackEvent(AnalyticsEvent.error_needs_mapping, {
			code: error.code,
		});
	}

	return defs.defaultError;
};

export default useErrorTransformer;

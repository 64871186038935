import { CommonActions, Route, useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

import NavigatorKey from '../navigation/NavigatorKey';
import ScreenKey from '../navigation/ScreenKey';

/**
 * When we redirect to checkout or any screen with presentation mode as modal
 * the screen appears broken as users cannot close the modal because the navigation is blocked.
 * This is because, for the native modal to work as intended, there needs to be an initial screen.
 */
const useResetNavigation = () => {
	const navigation = useNavigation();

	return useCallback(
		(screen: Omit<Route<string>, 'key'>) =>
			navigation.dispatch(
				CommonActions.reset({
					index: 1,
					routes: [
						{
							name: NavigatorKey.MainNavigator,
							params: { screen: ScreenKey.Home },
						},
						screen,
					],
				}),
			),
		[navigation],
	);
};

export default useResetNavigation;

import { ReactElement, ReactNode } from 'react';

import platform from '../utils/platform';
import { AnalyticsEvent } from './AnalyticsEvent';
import { AnalyticsPayload } from './AnalyticsPayload';

export type AnalyticsProvider = ({}: {
	platform: typeof platform;
	children: ReactNode;
}) => ReactElement | null;

export enum AnalyticsProviderName {
	AppsFlyer = 'appsflyer',
	Firebase = 'firebase',
	Customerio = 'customerio',
	Intercom = 'intercom',
	LogRocket = 'logrocket',
	Mixpanel = 'mixpanel',
	Mojo = 'mojo',
}

export type TrackEventHook = <
	Event extends AnalyticsEvent,
	Payload extends AnalyticsPayload[Event],
>() => (
	eventName: Event,
	payload: Payload,
	transformer?: (
		provider: AnalyticsProviderName,
		payload: Payload,
	) => {} | false,
) => void;

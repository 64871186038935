import { skipToken } from '@reduxjs/toolkit/query';

import { HandleableErrorCode } from '../errors/HandleableError';
import api from '../store/api';
import { selectUserId } from '../store/session';
import useEndpointQuery from './queries/useEndpointQuery';
import useHandleImpersonationAccessDenied from './queries/useHandleImpersonationAccessDenied';
import { useAppSelector } from './useAppSelector';

const usePseudonym = () => {
	const userId = useAppSelector(selectUserId);

	const res = useEndpointQuery(
		api.endpoints.getPseudonym.useQuery(userId ? { userId } : skipToken),
		{
			customHandleErrors: [
				HandleableErrorCode.access_denied,
				HandleableErrorCode.user_not_consented,
			],
		},
	);

	useHandleImpersonationAccessDenied(res.error);

	return res;
};

export default usePseudonym;

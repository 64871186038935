import { useMemo } from 'react';

import { getRuntimeInfo, getUpdateInfo } from '../utils/buildInfo';

const useBuildInfo = () => {
	const runtimeInfo = useMemo(() => getRuntimeInfo(), []);
	const updateInfo = useMemo(() => getUpdateInfo(), []);
	return useMemo(
		() => ({
			runtimeInfo,
			updateInfo,
		}),
		[runtimeInfo, updateInfo],
	);
};

export default useBuildInfo;

export default {
	type: 'generic' as const,
	title: 'Something went wrong',
	message: [
		{
			_type: 'block',
			_key: '2d87923a9d47',
			style: 'normal',
			markDefs: [
				{
					_type: 'contentVariable',
					_key: '4f4f0e0a92ed',
				},
			],
			children: [
				{
					_type: 'span',
					_key: '212b54368b5a',
					text: 'An unexpected error has occurred (',
					marks: [],
				},
				{
					_type: 'span',
					_key: '4fa5a3bc1e1f',
					marks: ['4f4f0e0a92ed'],
					text: 'error.code',
				},
				{
					_type: 'span',
					_key: 'db05699b11f8',
					marks: [],
					text: '). Please try again, or contact support for help.',
				},
			],
		},
	],
	actions: [],
};

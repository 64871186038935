import { ModalHeader, ModalHeaderProps } from '@wearemojo/ui-components';

import IntercomButton from './IntercomButton';

type Props = ModalHeaderProps & {
	helpType?: 'support';
	helpContext: string;
};

const ModalScreenHeader = ({
	helpType = 'support',
	helpContext,
	...rest
}: Props) => {
	return (
		<ModalHeader
			{...rest}
			supportButton={<IntercomButton type={helpType} context={helpContext} />}
		/>
	);
};

export default ModalScreenHeader;

import { WhoopsError, WhoopsView } from '@wearemojo/ui-components';

import { isFinalAppRelease } from '../../environment';

export default ({
	whoopsError,
	onPressDismiss,
	onPressSupport,
}: {
	whoopsError: WhoopsError;
	onPressDismiss?: () => void;
	onPressSupport?: () => void;
}) => (
	<WhoopsView
		{...whoopsError}
		onPressDismiss={onPressDismiss}
		onPressSupport={onPressSupport}
		initialShowFormattedError={!isFinalAppRelease}
	/>
);

import * as Localization from 'expo-localization';
import { Platform } from 'react-native';

export const useLocalization = () => {
	const timezone =
		Platform.OS === 'web'
			? Intl.DateTimeFormat().resolvedOptions().timeZone
			: Localization.timezone;

	return { timezone };
};

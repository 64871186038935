import { DeleteAccountView, ModalNav } from '@wearemojo/ui-components';
import { Modal } from 'react-native';

import { FragmentType } from '../content/FragmentType';
import { useFragment } from '../hooks/content/useFragment';

type Props = {
	visible: boolean;
	isDeletingAccount: boolean;
	onContinuePress: () => void;
	onSupportPress: () => void;
	onCloseModal: () => void;
};

const DeleteAccountModal = ({
	visible,
	onCloseModal,
	isDeletingAccount,
	onContinuePress,
	onSupportPress,
}: Props) => {
	const userDeletion = useFragment(FragmentType.UserDeletion);

	const controls = (
		<ModalNav.Controls
			direction="column"
			previous={{
				title: userDeletion.confirmationCtaText,
				loading: isDeletingAccount,
				type: 'warning',
				onPress: () => {
					if (isDeletingAccount) return;
					onContinuePress();
				},
			}}
			next={{
				title: userDeletion.cancelCtaText,
				type: 'secondary',
				disabled: isDeletingAccount,
				onPress: onCloseModal,
			}}
		/>
	);

	return (
		<Modal visible={visible} transparent>
			<DeleteAccountView
				title={userDeletion.confirmationTitle}
				content={userDeletion.confirmationMessage}
				controls={controls}
				onClosePress={onCloseModal}
				contactSupportCtaText={userDeletion.contactSupportCtaText}
				onSupportPress={onSupportPress}
			/>
		</Modal>
	);
};

export default DeleteAccountModal;

import React, {
	createContext,
	ReactNode,
	useCallback,
	useMemo,
	useState,
} from 'react';

import useNetworkStatus, { NetworkStatus } from '../hooks/useNetworkStatus';
import FailedToConnectScreen from '../screens/FailedToConnectScreen';
import { logger } from '../utils/logging';

type Props = { children: ReactNode };

const AppInstance = ({ children }: Props) => {
	const [key, setKey] = useState(1);
	const { networkStatus, retryConnection } = useNetworkStatus();
	const reload = useCallback(() => setKey((prevKey) => prevKey + 1), [setKey]);

	const context = useMemo(
		() => ({
			key,
			reload,
		}),
		[key, reload],
	);

	if (networkStatus === NetworkStatus.offline) {
		return <FailedToConnectScreen onPressRetry={retryConnection} />;
	}

	return (
		<AppInstanceContext.Provider value={context}>
			<React.Fragment key={key}>{children}</React.Fragment>
		</AppInstanceContext.Provider>
	);
};

export const AppInstanceContext = createContext({
	key: 1,
	reload: () => logger.captureError('AppInstance not initialized'),
});

export default AppInstance;

import { useMemo } from 'react';

import { defaultBackendConfig } from '../../environment';
import { selectBackendConfigOverride } from '../store/dev';
import { useAppSelector } from './useAppSelector';

const useBackendConfig = () => {
	const override = useAppSelector(selectBackendConfigOverride);

	return useMemo(() => override ?? defaultBackendConfig, [override]);
};

export default useBackendConfig;

import { useFeature } from './useFeature';

const useEroticStoriesTabFeature = () => {
	const { isOn: isEroticStoriesTabOn } =
		useFeature<boolean>('erotic_stories_tab');

	return { isEroticStoriesTabOn };
};

export default useEroticStoriesTabFeature;

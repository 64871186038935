import { useCallback } from 'react';

import {
	useIntercomUnreadMessagesCount,
	usePresentIntercom,
} from '../utils/analytics/intercom';
import { PresentIntercomParams } from '../utils/analytics/intercom.types';

const useAppIntercom = () => {
	const presentIntercom = usePresentIntercom();
	const unreadMessagesCount = useIntercomUnreadMessagesCount();

	const onPresentIntercom = useCallback(
		async (payload: PresentIntercomParams) => {
			presentIntercom(payload);
		},
		[presentIntercom],
	);

	return {
		presentIntercom: onPresentIntercom,
		unreadMessagesCount,
	};
};

export default useAppIntercom;

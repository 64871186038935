import { ReactElement, ReactNode, useMemo } from 'react';

import { AnalyticsProviderLogRocket } from '../services/logrocket';
import { AnalyticsProviderAppsFlyer } from '../utils/analytics/appsflyer';
import { AnalyticsProviderCustomerio } from '../utils/analytics/customerio';
import { AnalyticsProviderFirebase } from '../utils/analytics/firebase';
import { AnalyticsProviderIntercom } from '../utils/analytics/intercom';
import { AnalyticsProviderMixpanel } from '../utils/analytics/mixpanel';
import { AnalyticsProviderMojo } from '../utils/analytics/mojo';
import platform from '../utils/platform';

type AnalyticsProviders = ({}: { children: ReactNode }) => ReactElement;

const providers = [
	AnalyticsProviderAppsFlyer,
	AnalyticsProviderFirebase,
	AnalyticsProviderCustomerio,
	AnalyticsProviderLogRocket,
	AnalyticsProviderMixpanel,
	AnalyticsProviderMojo,

	// intercom is rendered as the innermost provider, because on web it blocks
	// rendering until it's booted
	AnalyticsProviderIntercom,
];

export const AnalyticsProviders: AnalyticsProviders = ({
	children,
	...rest
}) => {
	return useMemo(
		() =>
			providers.reverse().reduce(
				(acc, Provider) => (
					<Provider platform={platform} {...rest}>
						{acc}
					</Provider>
				),
				<>{children}</>,
			),
		[children, rest],
	);
};

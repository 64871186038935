import { ActionType } from '@wearemojo/sanity-schema';
import {
	ActionLink,
	LinkProviderType,
	useUIContext,
} from '@wearemojo/ui-components';
import { useCallback } from 'react';
import { Linking } from 'react-native';

import { createLinkOnPress } from '../../navigation/LinkProvider';
import { useCreateScreenLinkOnPress } from '../../navigation/links';
import ScreenKey from '../../navigation/ScreenKey';
import { usePresentIntercom } from '../../utils/analytics/intercom';
import useAppReload from '../useAppReload';
import useOnLogout from '../useOnLogout';

/**
	Potential pitfull to be aware of! This transformer is used to render actions
	in the whoops modal. Such modals (rendered via our ModalManager) render outside
	the navigation provider, so we can't utilise the usual createScreenLinkTo or
	<LinkProvider> component, as that generates a component that requires the
	navigation context.

	(Note that at the time of writing, this doesn't generate an error, but the
	resulting action will not function, on web you can also see the URL will just
	show the raw screen key and params, rather than the path in the linking config)

	However we can still utilise the navigation context direct within this hook,
	and generate a <LinkOnPressProvider>, a LinkProvider style component that takes
	an onPress and doesn't otherwise utilize the navigation context outside of this
	hook (Downside is that we won't be generating links with hrefs on web for now).
 */

type ExtraOption = {
	onPressEffect?: () => void;
};

const useTransformActionToLinkProvider = (options?: ExtraOption) => {
	// @TODO: how to give a clean way to decorate actions with onPressEffect?
	// Needed for closing whoops modal

	const { isWeb } = useUIContext();
	const onPressReload = useAppReload();
	const { onLogout } = useOnLogout();
	const presentIntercom = usePresentIntercom();
	const createScreenLinkOnPress = useCreateScreenLinkOnPress();
	const { onPressEffect } = options || {};

	return useCallback(
		(action: ActionType): ActionLink | undefined => {
			let linkProvider = unknownActionLinkProvider(action._type);

			if (action._type === 'navigate') {
				// @ts-ignore dynamic fields from backend
				const { screen, params } = action;
				linkProvider = createScreenLinkOnPress(
					screen as ScreenKey,
					params ? JSON.parse(params) : undefined,
					{ onPressEffect }, // @TODO implement on other action types (need to adapt other link provider utils to accept)
				);
			}

			if (action._type === 'logout') {
				linkProvider = createLinkOnPress(onLogout);
			}

			if (action._type === 'reloadApp') {
				linkProvider = createLinkOnPress(onPressReload);
			}

			if (action._type === 'openUrl') {
				const { url } = action;
				linkProvider = createLinkOnPress(() => Linking.openURL(url));
			}

			if (action._type === 'openSystemSettings') {
				if (isWeb) return;
				linkProvider = createLinkOnPress(() => Linking.openSettings());
			}

			if (action._type === 'contactSupport') {
				linkProvider = createLinkOnPress(() =>
					presentIntercom({
						type: 'support',
						context: 'whoops/ContactSupport',
					}),
				);
			}

			const { title } = action;

			return {
				title,
				linkProvider,
			};
		},
		[
			createScreenLinkOnPress,
			isWeb,
			onLogout,
			onPressEffect,
			onPressReload,
			presentIntercom,
		],
	);
};

const unknownActionLinkProvider =
	(actionType: string): LinkProviderType =>
	() => {
		throw new Error(`Unknown action type: ${actionType}`);
	};

export default useTransformActionToLinkProvider;

import { useRoute } from '@react-navigation/native';
import { useCallback } from 'react';

import { useTrackEvent } from '../../analytics/trackEvent';
import { AnalyticsEvent } from './../../analytics';

const useTrackModalClose = () => {
	const trackEvent = useTrackEvent();
	const route = useRoute();

	return useCallback(() => {
		if (!route) return;

		trackEvent(AnalyticsEvent.modal_closed, {
			name: route.name,
			path: route.path,
			params: route.params,
		});
	}, [route, trackEvent]);
};

export default useTrackModalClose;

import { WebUtil } from '@wearemojo/ui-components';
import { UIPlatform } from '@wearemojo/ui-constants';
import { useEffect } from 'react';

import platform from '../utils/platform';

const useGlobalStyles = () => {
	useEffect(() => {
		if (platform === UIPlatform.web) {
			WebUtil.injectGlobalStyles();
		}
	}, []);
};

export default useGlobalStyles;

import { ServiceResponses } from '@wearemojo/api-client';
import { useCallback } from 'react';

import { AnalyticsEvent } from '../../analytics';
import { useTrackEvent } from '../../analytics/trackEvent';
import { selectSessionId, selectUserId } from '../../store/session';
import { useCustomerioSetProfileAttributes } from '../../utils/analytics/customerio';
import { useIntercomUpdateUser } from '../../utils/analytics/intercom';
import { useAppSelector } from '../useAppSelector';

type ValueOf<T> = T[keyof T];
type ExperimentResult = ValueOf<
	ServiceResponses['featureconfig']['getFeaturesRaw']['experiments']
>;

const trackedExperiments = new Set<string>();

export const useTrackExperiment = () => {
	const trackEvent = useTrackEvent();
	const customerioSetProfileAttributes = useCustomerioSetProfileAttributes();
	const intercomUpdateUser = useIntercomUpdateUser();
	const sessionId = useAppSelector(selectSessionId);
	const userId = useAppSelector(selectUserId);

	return useCallback(
		(result: ExperimentResult) => {
			const { experiment_key: experimentKey, result_key: resultKey } = result;

			const trackingKey = `${sessionId}/${userId}/${experimentKey}/${resultKey}`;
			if (trackedExperiments.has(trackingKey)) return;
			trackedExperiments.add(trackingKey);

			trackEvent(AnalyticsEvent.mp_experiment_started, {
				experiment_key: experimentKey,
				variation_id: resultKey,
				'Experiment name': experimentKey,
				'Variant name': resultKey,
			});

			customerioSetProfileAttributes({
				[`experiment_${experimentKey}`]: resultKey,
			});

			intercomUpdateUser({
				customAttributes: {
					[`experiment_${experimentKey}`]: resultKey,
				},
			});
		},
		[
			customerioSetProfileAttributes,
			intercomUpdateUser,
			sessionId,
			trackEvent,
			userId,
		],
	);
};

export const useTrackExperimentIntercomOnly = () => {
	const customerioSetProfileAttributes = useCustomerioSetProfileAttributes();
	const intercomUpdateUser = useIntercomUpdateUser();
	const sessionId = useAppSelector(selectSessionId);
	const userId = useAppSelector(selectUserId);

	return useCallback(
		(result: ExperimentResult) => {
			const { experiment_key: experimentKey, result_key: resultKey } = result;

			const trackingKey = `${sessionId}/${userId}/${experimentKey}/${resultKey}`;
			if (trackedExperiments.has(trackingKey)) return;
			trackedExperiments.add(trackingKey);

			customerioSetProfileAttributes({
				[`experiment_${experimentKey}`]: resultKey,
			});

			intercomUpdateUser({
				customAttributes: {
					[`experiment_${experimentKey}`]: resultKey,
				},
			});
		},
		[customerioSetProfileAttributes, intercomUpdateUser, sessionId, userId],
	);
};

import { SerializedError } from '@reduxjs/toolkit';
import Cher from '@wearemojo/cher';

import { HandleableErrorCode } from '../../errors/HandleableError';
import useEndpointQuery, { ErrorReturn } from './useEndpointQuery';

// the custom error handling is fully implemented for the result
// but the trigger's promise does still provide the original error
const useEndpointMutation = <
	UseMutationTrigger extends (...args: any) => any,
	UseMutationResult extends { error?: Cher | SerializedError },
	CustomHandleError extends HandleableErrorCode = never,
>(
	mutation: readonly [UseMutationTrigger, UseMutationResult],
	{
		customHandleErrors = [],
		onDismissEffect,
	}: {
		customHandleErrors?: CustomHandleError[];
		onDismissEffect?: () => void;
	} = {},
): readonly [
	(
		...args: Parameters<UseMutationTrigger>
	) => Omit<ReturnType<UseMutationTrigger>, 'unwrap'>,
	Omit<UseMutationResult, 'error'> & {
		error: ErrorReturn<CustomHandleError>;
	},
] => {
	const [trigger, result] = mutation;

	const res = useEndpointQuery(result, { customHandleErrors, onDismissEffect });

	return [trigger, res];
};

export default useEndpointMutation;

import { UAParser } from 'ua-parser-js';

import { logger } from '../utils/logging';

const getParsedBrowserName = () => {
	try {
		const parser = new UAParser(window.navigator.userAgent);
		return parser.getBrowser().name;
	} catch (error) {
		logger.captureError('Error parsing browser name', { error });
	}
};

export const parsedBrowserName = getParsedBrowserName();

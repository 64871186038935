import { useCallback } from 'react';

import useFeaturesRaw from '../store/api/hooks/useFeaturesRaw';
import { useTrackExperiment } from './analytics/useTrackExperiment';

export const useEvaluateFeature = () => {
	const trackExperiment = useTrackExperiment();
	const { data, isFetching, isLoading } = useFeaturesRaw();

	const evaluateFeature = useCallback(
		<T>(
			featureKey: string,
		): {
			value: T | null | undefined;
			isOn: boolean | undefined;
		} => {
			const value = data?.values[featureKey] as T | undefined;
			const experimentResult = data?.experiments[featureKey];

			if (experimentResult) {
				trackExperiment(experimentResult);
			}

			return {
				value: data ? (value ?? null) : undefined,
				isOn: data ? !!value : undefined,
			};
		},
		[data, trackExperiment],
	);

	return {
		featuresRaw: data,
		isFetching,
		isLoading,
		evaluateFeature,
	};
};

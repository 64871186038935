import { useEffect, useRef } from 'react';
import { AppState } from 'react-native';

const useOnAppFocus = (callback: () => void) => {
	const appState = useRef(AppState.currentState);
	useEffect(() => {
		const subscription = AppState.addEventListener('change', (nextAppState) => {
			if (
				appState.current.match(/inactive|background/) &&
				nextAppState === 'active'
			) {
				callback();
			}
			appState.current = nextAppState;
		});

		return () => {
			subscription.remove();
		};
	}, [callback]);
};

export const useOnAppFocusFirstActive = (callback: () => void) => {
	const triggered = useRef(false);

	useEffect(() => {
		// Check on mount if it is active
		if (AppState.currentState === 'active') {
			triggered.current = true;
			callback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// And add a listener in case the initial state wasn't active
		const subscription = AppState.addEventListener('change', (nextAppState) => {
			if (!triggered.current && nextAppState === 'active') {
				triggered.current = true;
				callback();
			}
		});

		return () => {
			subscription.remove();
		};
	}, [callback]);
};

export default useOnAppFocus;

import 'expo-dev-client';
import '@wearemojo/ui-components/unistyles';

import { BackendSystem } from '@wearemojo/api-client';
import mojoFonts from '@wearemojo/fonts';
import { LoadingIndicator, withErrorBoundary } from '@wearemojo/ui-components';
import { Font } from '@wearemojo/ui-constants';
import { useFonts } from 'expo-font';
import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import Purchases from 'react-native-purchases';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { isFinalAppRelease } from '../environment';
import { getAdvertisingId, getVendorId } from './analytics/deviceIds';
import { AnalyticsProviders } from './analytics/providers';
import AnalyticsEffects from './AnalyticsEffects';
import AppEffects from './AppEffects';
import AppInstance from './components/AppInstance';
import StaffKnob from './components/StaffKnob';
import { initSentry } from './errors/sentry';
import useErrorTransformer from './errors/useErrorTransformer';
import WhoopsProvider from './errors/WhoopsProvider';
import useGetRevenuecatConfig from './hooks/iap/useGetRevenuecatConfig';
import useSplashScreen from './hooks/splashscreen/useSplashScreen';
import { useAppSelector } from './hooks/useAppSelector';
import useBackendConfig from './hooks/useBackendConfig';
import useIsAppReady from './hooks/useIsAppReady';
import ModalManager from './ModalManager';
import RootNavigator from './navigators/RootNavigator';
import AudioPlayerProvider from './providers/AudioPlayerProvider';
import ClientContextProvider from './providers/ClientContextProvider';
import CMSGlobalContextProvider from './providers/CMSGlobalContextProvider';
import DataProviders from './providers/DataProviders';
import FlowProvider from './providers/FlowProvider';
import NavigationProviders from './providers/NavigationProviders';
import NetworkStateProvider from './providers/NetworkStateProvider';
import StateProviders from './providers/StateProviders';
import SubscriptionStateProvider from './providers/SubscriptionStateProvider';
import { SupportSheetProvider } from './providers/SupportSheetProvider';
import UIProviders from './providers/UIProviders';
import { getAppsFlyerId } from './services/appsflyer';
import { initLogRocket } from './services/logrocket';
import useIsStaffMember from './store/api/hooks/useIsStaffMember';
import { selectImpersonatedUserId } from './store/session';
import StyleEffects from './StyleEffects';
import { usePresentIntercom } from './utils/analytics/intercom';
import { logger } from './utils/logging';
import withInteractionEvents from './utils/withInteractionEvents';

const fonts: Record<Font, any> = {
	[Font.body]: mojoFonts[Font.body],
	[Font.bodyBold]: mojoFonts[Font.bodyBold],
	[Font.bodyItalic]: mojoFonts[Font.bodyItalic],
	[Font.bodyBoldItalic]: mojoFonts[Font.bodyBoldItalic],
	[Font.heading]: mojoFonts[Font.heading],
	[Font.headingBold]: mojoFonts[Font.headingBold],
	[Font.headingExtraBold]: mojoFonts[Font.headingExtraBold],
	[Font.headingItalic]: mojoFonts[Font.headingItalic],
	[Font.headingBoldItalic]: mojoFonts[Font.headingBoldItalic],
	[Font.headingExtraBoldItalic]: mojoFonts[Font.headingExtraBoldItalic],
};

if (!__DEV__) {
	// Sentry wraps console.log, making following stack traces a pain during dev
	initSentry();
	initLogRocket();
}

const App = () => {
	const { system, env } = useBackendConfig();
	const impersonatedUserId = useAppSelector(selectImpersonatedUserId);
	const showStaffKnob = useAppSelector((state) => state.dev.showStaffKnob);
	const isReady = useIsAppReady();
	const isStaffMember = useIsStaffMember().data;
	const showKnob =
		__DEV__ ||
		showStaffKnob ||
		system !== BackendSystem.prod ||
		env !== 'prod' ||
		isStaffMember?.isStaffMember ||
		!!impersonatedUserId;

	const { closeModal } = ModalManager.useModal();
	const errorTransformer = useErrorTransformer({
		onPressEffect: closeModal,
	});
	const presentIntercom = usePresentIntercom();

	const onPressSupport = useCallback(() => {
		presentIntercom({
			type: 'support',
			context: 'header/Whoops',
		});
	}, [presentIntercom]);

	const { data, isLoading } = useGetRevenuecatConfig();
	useEffect(() => {
		if (Platform.OS !== 'ios' || isLoading || !data?.apiKey) return;
		/* Enable debug logs before calling `setup`. */
		const logLevel = isFinalAppRelease
			? Purchases.LOG_LEVEL.INFO
			: Purchases.LOG_LEVEL.DEBUG;
		Purchases.setLogLevel(logLevel);
		Purchases.configure({
			// this api key is linked to the app in revenue cat, and is used to identify the app
			apiKey: data?.apiKey,
			appUserID: data?.appUserId ?? null,
			useAmazon: false,
		});

		(async () => {
			// Setup for RevenueCat <> AppsFlyer integration
			try {
				const appsFlyerId = await getAppsFlyerId();
				if (appsFlyerId) Purchases.setAppsflyerID(appsFlyerId);
			} catch (error) {
				logger.captureException(error);
			}
			try {
				const vendorId = await getVendorId();
				if (Platform.OS === 'ios') Purchases.setAttributes({ $idfv: vendorId });
				// if (Platform.OS === 'android') Purchases.setAttributes({ $androidId: vendorId });
			} catch (error) {
				logger.captureException(error);
			}
			try {
				const advertisingId = await getAdvertisingId();
				if (Platform.OS === 'ios')
					Purchases.setAttributes({ $idfa: advertisingId });
				// if (Platform.OS === 'android') Purchases.setAttributes({ $gpsAdId: advertisingId });
			} catch (error) {
				logger.captureException(error);
			}
		})();
	}, [data?.apiKey, data?.appUserId, isLoading, data]);

	if (!isReady) {
		return <LoadingIndicator label="Content & program state" />;
	}

	return (
		<WhoopsProvider
			errorTransformer={errorTransformer}
			onPressDismiss={closeModal}
			onPressSupport={onPressSupport}
		>
			<AnalyticsEffects />
			<StyleEffects />
			<RootNavigator />
			{showKnob && <StaffKnob />}
		</WhoopsProvider>
	);
};

const AppWithErrorBoundary = withInteractionEvents(withErrorBoundary(App));

const AppWithProviders = () => {
	const [fontsLoaded] = useFonts(fonts);
	useSplashScreen(fontsLoaded);

	if (!fontsLoaded) {
		// Attempting to render any <Text> elements will crash without fonts loaded
		return null;
	}

	return (
		<AppInstance>
			<SafeAreaProvider>
				<StateProviders>
					<NetworkStateProvider>
						<UIProviders>
							<ModalManager.Provider>
								<WhoopsProvider>
									<ClientContextProvider>
										<AudioPlayerProvider>
											<DataProviders>
												<SubscriptionStateProvider>
													<AnalyticsProviders>
														<FlowProvider>
															<SupportSheetProvider>
																<NavigationProviders>
																	<CMSGlobalContextProvider>
																		<AppEffects />
																		<AppWithErrorBoundary />
																	</CMSGlobalContextProvider>
																</NavigationProviders>
															</SupportSheetProvider>
														</FlowProvider>
													</AnalyticsProviders>
												</SubscriptionStateProvider>
											</DataProviders>
										</AudioPlayerProvider>
									</ClientContextProvider>
								</WhoopsProvider>
							</ModalManager.Provider>
						</UIProviders>
					</NetworkStateProvider>
				</StateProviders>
			</SafeAreaProvider>
		</AppInstance>
	);
};

export default AppWithProviders;

import type { FragmentTypes } from '../../content/FragmentType';
import { FragmentType } from '../../content/FragmentType';
import api from '../../store/api';
import useEndpointQuery from '../queries/useEndpointQuery';

export const useFragment = <T extends FragmentType>(typeName: T) => {
	const { data: fragments } = useEndpointQuery(
		api.endpoints.getFragments.useQuery(),
	);

	if (!fragments) {
		throw new Error('Fragments not loaded');
	}

	const fragment = fragments.find(({ _type }) => _type === typeName);
	if (!fragment) {
		throw new Error(`Fragment missing: ${typeName}`);
	}
	return fragment as FragmentTypes[T];
};
